import firebase from "firebase/compat/app";
import "firebase/compat/database";

let config = {
  apiKey:
    "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDQlBkDSYaOpJjn\nCUVRTOlDZaHCwpJsVoWkXLyO7ht8Yn+sNz4dpensmYtMl9nHZiSMQBFTr+oKH5wd\nZvs3Vo0ztKR/dXRQkhMrNnr7qnAAmlHyEbgcUnew2qapYfYHUEmeE2n03DQLO5j0\nACO3oZjsaNVIFsvEPCmmayA+pr9V5c33tYKrku3K5MbSdr8eCP3uq+x+/Okdm/a8\n7K8iQ+qmmRwuh3rGCq4i4Asc+x55qpn7JNPfbZnn7qFj55Z/KG4xBk9lEZDvNvD4\nF5DPChjQOKPeaSPoN9MH11WCI7lE9X3zcncyCn3UXeTyDW/4/OcPhgZwxcjQTHbf\nkmw1tSjjAgMBAAECggEAFP9Ls1KECrkUMXHz+fbBpsjE64vV3LUrF/xD+SmMN83c\np3QaOzbYITrl1IyW3mVweXP7qo6Tg3hiKW2UDaKXbWSaiMBEuNWzu05tPAeQ5eaj\nKDjTbXp51SJ106hqcZsKBjFTIdTHInhZ8fpPni1U7TuyeOLemX8q/DddlCEKMvXU\nMuX1A2bsbQ2kHB9+OlFxIq4bbOzwe6O5yIN6W/NdiIyi7dU8PGyahhpvmbchwnRE\nHxyP9uijMehjIFfO/weAVjPn3elUbnbfOYkk7xJt0JfSceEh5iuU7ik8dMDumi4G\nLRWixWtFw6wPJ0Am4hYy4ELq/LhVTkn/He182cVFUQKBgQD+tizj7XiXTz5kJCCI\nvDNr8kjWmNR/XB4nVkfMm24V9jZ7cecWKXhehiHvN82sS5wAqE7IdlFLcrX4kXTd\nrG4cXXlnULIIpC2j5SfPTPM8Bple+K36+zv5XYUD5WE0ArqNcJBYODrJSOReBtsL\nLcjmnvCqThrUWS6l8NF6NZOakwKBgQDRoi9Rw4Su1l4l7OHtSRJWPp0XX04EL+Bu\nDi74Abbd8lzJ9ztAXSKyiAsB/RcduQcHjAsaqT9NaJGJEpdgkq8rSZkMj6QuJLAH\nRY9BS8pZISzC4axYi0yOQzJ/AwJMVLvxurVFVH3EOSR6gmEWYkcdCfBwdm4ciypp\nRkTTEJkacQKBgHhOvfdOlDYL1PFh6wgKtNMY1nSeLUiWzxtPcoxfIek3qVY3X6ol\nHlivTFn8ZqYp9F6PIN8om49ieDcXJTcLGdtF/3DgOadBPNl8BIiy1PSWPV0RYsP4\nZMSM+qXzcy0o2Mqzz0oMDSBD0LHk1RN7pbfgfNZEHAIOuazhiWAK7FQHAoGAT6hI\nQNKBrYoJlnGv2+GjVSBPwxzU4WRr7IX5NQY5vZalyZznZ+q8D4kAXc4VJZc0Y5rE\ncWoUgs6UVri4ytqSM2mw/YPriBkqxmiZKd+p0Tp47sigG7k66uf/3SGAkw6zqAQh\nc2nKrHBmOyMlz0Ki8RbgxWtLJg9/myc3adhpxRECgYBDZcc+eUFdyMZuO4S2dzgG\nPX5WXLzWdN63fiVN42WRxv8fk9FIfIq8v421cCJndCCFknLY+jJFP4XNrdbAkhDg\nOj+x6zQ3HLChRIeNr8Br+2dhgwbdMfDcfzzhN+95NeDyWnt65If5CTTQTmAaT31y\nIS/CAyagth0wKt9ieXxhuQ==\n-----END PRIVATE KEY-----\n",
  authDomain: "soft-faces-firebase.firebaseapp.com",
  databaseURL: "https://soft-faces-default-rtdb.firebaseio.com",
  projectId: "soft-faces",
  storageBucket: "soft-faces.appspot.com",
  messagingSenderId: "972275871970",
  appId: "102655500147527534275",
};

firebase.initializeApp(config);
const ref = firebase.database().ref();

export default ref;
