import React from "react";

export default function footer() {
  return (
    <div className="pt-1 pt-md-0">
      <div
        className="d-block d-md-flex flex-wrap justify-content-center "
        style={{ color: "#FF00FF" }}
      >
        <p className="text-center font22Px px-2">→FAQ</p>
        <p className="text-center font22Px px-2">→CONTRACT</p>
        <p className="text-center font22Px px-2">→OPENSEA</p>
        <p className="text-center font22Px px-2">→TWITTER</p>
      </div>
      <div className="text-center">
        <img
          onClick={(x) => {
            window.location.reload();
          }}
          className="py-3 d-block d-md-none mx-auto"
          src="logo-pink.png"
          alt=""
        />
      </div>
    </div>
  );
}
