import React from "react";

export default function Faq() {
  return (
    <div
      className="py-5 bio text-center mx-auto px-2"
      style={{ maxWidth: "900px" }}
    >
      <p className="allCaps pb-2">What is Soft Faces?</p>

      <p>
        "Soft Faces" is a collection of 77 generative NFTs that are stored,
        rendered and interact with the other "Soft Faces" on chain.
      </p>
      <p>
        This is an experiment to explore the formation of on-chain avatar
        communities. "Soft Faces" attempt to give a group an identity by
        communicating, ergo, expressing their feelings. Each "Soft Face" starts
        with a base mood that is defined by a selfie of the minter.
      </p>
      <p>
        All the "Soft Faces" together respond to fluctuations in the Ethereum
        price. They become happier (wgmi) when the ETH price goes up, and they
        become more sad (ngmi) when it goes down.
      </p>
      <p>
        Each "Soft Face" holder can also set a temporary mood (happy, surprised,
        angry, confused, sad) for the whole collection via a smart contract
        interaction.
      </p>
      <img className="my-5" src="logo-black.png" alt="" />
      <p className="allCaps mt-1 pb-2">HOW DOES IT WORK?</p>

      <p>
        First, you take a selfie. An emotion detection algorithm analyzes the
        image and gives it a mood value that is visualized in the generated
        "Soft Face". The selfie serves as a hash to generate your "Soft Face".
        Each "Soft Face" is unique and has different attributes like colors,
        blur and animation traits. Once you find a "Soft Face" you like, you can
        mint it. Every "Soft Face" is stored and rendered on chain.
      </p>
      <img className="my-5" src="logo-black.png" alt="" />
      <p className="allCaps mt-1 pb-2">
        Is any of my face data saved somewhere?
      </p>

      <p>
        NO! Privacy is very important to us. All of the computation is happening
        on the frontend and not on any server. Plus we’re not saving anything
        besides the SVG and mood properties on chain. We like to stay anon with
        our wallets.
      </p>
      <img className="my-5" src="logo-black.png" alt="" />
      <p className="allCaps mt-1 pb-2">WHAT IS THE PRICE OF A SOFT FACE?</p>

      <p>The cost to mint is 0.1 ETH.</p>
      <img className="my-5" src="logo-black.png" alt="" />
      <p className="allCaps mt-1 pb-2">Technical notes?</p>

      <p>
        "Soft Faces" are stored directly on Ethereum and do not utilize pointers
        to other networks such as IPFS. This is possible because "Soft Faces"
        are SVGs stored on-chain. They function as responsive NFTs, changing
        state based on data drawn from an on-chain oracle.
      </p>
      <img className="my-5" src="logo-black.png" alt="" />
      <p className="allCaps mt-1 pb-2">Interactions?</p>

      <p>
        Holders of "Soft Faces" can interact with the smart contract and change
        the mood for the whole group to: happy, sad, angry or even fearful. The
        last mint from the collection also triggers the “Soft Faces Rave Day” –
        you’ll see on that day what happens. Perhaps there are also some easter
        eggs – who knows?
      </p>
      <img className="my-5" src="logo-black.png" alt="" />
      <p className="allCaps mt-1 pb-2">What is the collection?</p>

      <p>
        "Soft Faces" is a collection of 77 on-chain NFTs. Each "Soft Face" is a
        unique, generative NFT. Its attributes are determined based on randomly
        seeded values generated by a hash distilled from a selfie. Each NFT is
        stored, rendered and interacts with the other "Soft Faces" on chain.
      </p>
      <img className="my-5" src="logo-black.png" alt="" />

      <p className="allCaps mt-1 pb-2">MORE ABOUT THE ARTIST?</p>

      <p>
        Damjanski is an artist living in a browser. Concerned with themes of
        power, poetry and participation, he explores the concept of apps as
        artworks. The app "Bye Bye Camera" is the camera for the post-human era.
        Every picture people take automatically removes any person. The app
        "Computer Goggles" let’s people capture the world like a machine sees it
        and the "LongARcat" app creates long cats in AR.
      </p>
      <p>
        In 2018, he co-founded "MoMAR", an Augmented Reality gallery app aimed
        at democratizing physical exhibition spaces, art institutions and
        curatorial processes within New York’s Museum of Modern Art. WIRED
        covered the launch with the headline "Augmented Reality Is Transforming
        Museums".
      </p>
      <p>
        He created an online space that only programs can access. This software
        performance, called "Humans not invited", first hit Reddit’s front page
        before it was shown at the König Galerie in Berlin.
      </p>
      <p>
        His work has appeared internationally, including exhibitions at
        NRW-Forum, König Galerie, Roehrs & Boetsch, Pioneer Works, MoCDA,
        Tropez, Import Projects. Currently Damjanski resides in New York.
      </p>
      <p>
        <a
          className="hoverable"
          style={{ color: "black" }}
          href="http://www.damjanski.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          →Artist Website←
        </a>
      </p>
      <img className="my-5" src="logo-black.png" alt="" />
      <p className="pt-1">
        The Soft Faces smart contract and website were developed in
        collaboration with software engineer{" "}
        <a
          className="hoverable"
          style={{ color: "black" }}
          href="http://www.sosteve.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Steve Snygin
        </a>
        .
      </p>
    </div>
  );
}
