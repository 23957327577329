import React, { useState, useEffect, useRef } from "react";
import { make4Faces } from "./make4Faces";
import Webcam from "react-webcam";
import base64 from "base-64";
import * as faceapi from "face-api.js";
import firebase from "./firebase";
import NFTContract from "./contracts/NFTContract";
import Info from "./info";
import { ethers } from "ethers";
import Web3Modal, { isMobile } from "web3modal";
import Web3 from "web3";
import WalletConnect from "@walletconnect/web3-provider";
import Contract from "web3-eth-contract";
import Faq from "./faq";
import Footer from "./footer";

const INFURA_ID = "9b2ce6abf15f4577a2f233789eda4239";

const speedArray = [0, 1, 2];
const blurArray = [0, 1, 2, 3, 4, 5, 6];
const speedArrayName = ["Hypo", "Normal", "Hyper"];

export const providerOptions = {
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId: "9b2ce6abf15f4577a2f233789eda4239",
    },
  },
};

let test;

const contractAddress = Info.address;

const web3Modal = new Web3Modal({
  providerOptions, // required
});

const web3ForCalls = new Web3(
  new Web3.providers.HttpProvider(
    "https://rinkeby.infura.io/v3/9b2ce6abf15f4577a2f233789eda4239"
  )
);

const contractForCalls = new web3ForCalls.eth.Contract(
  NFTContract,
  contractAddress
);

async function getTotalMinted() {
  let res = await contractForCalls.methods.artBalance(contractAddress).call();
  return 77 - res;
}

async function isOwner(walletNumber) {
  let res = await contractForCalls.methods.balanceOf(walletNumber).call();
  if (res > 0) {
    return true;
  }

  return false;
}

async function calcEmotion() {
  let res = await contractForCalls.methods.calcEmotion(1).call();
  return res;
}

export default function App() {
  const videoRef = useRef();
  const canvasRef = useRef();
  const [section, setSection] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [faceSpeed, setFaceSpeed] = useState(2);
  const [faceBlur, setFaceBlur] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  const [face, setFace] = useState(
    "data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0IgogIHZpZXdCb3g9IjAgMCAxMDAgMTAwIgo+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJmMiIgeD0iLTEwMCUiIHk9Ii0xMDAlIiB3aWR0aD0iMTIwMDAlIiBoZWlnaHQ9IjEyMDAwJSI+CiAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0iU291cmNlR3JhcGhpYyIgc3RkRGV2aWF0aW9uPSIxMC41IiAvPgogICAgPC9maWx0ZXI+CiAgPC9kZWZzPgogIDxnPgogICAgPHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0iI0ZGRkY5QiIgLz4KICAgIDxjaXJjbGUgZmlsdGVyPSJ1cmwoI2YyKSIgZmlsbD0iI0ZFQTBDOSIgY3g9IjUwIiBjeT0iMTEwIiByPSI2MCIgLz4KICAgIDxjaXJjbGUKICAgICAgY2xhc3M9ImlubmVyQ2lyY2xlIgogICAgICBmaWx0ZXI9InVybCgjZjIpIgogICAgICBmaWxsPSIjRkYwMEZGIgogICAgICBjeD0iNTAiCiAgICAgIGN5PSIxMTAiCiAgICAgIHI9IjYwIgogICAgPgogICAgICA8YW5pbWF0ZQogICAgICAgIGF0dHJpYnV0ZU5hbWU9InIiCiAgICAgICAgdmFsdWVzPSI5MDs1MDs5MCIKICAgICAgICBkdXI9IjIwMDBtcyIKICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgLz4KICAgIDwvY2lyY2xlPgogIDwvZz4KPC9zdmc+"
  );
  const [lilFace0, setLilFace0] = useState("");
  const [isProcessing, setProcessing] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [provider, setProvider] = useState();
  const [colors, setColors] = useState("");
  const [mouthSize, setMouthSize] = useState(0);
  const [eyeLevel, setEyeLevel] = useState(0);
  const [mintedTokenId, setMintedTokenId] = useState("0");

  const [wallet, setWallet] = useState("→CONNECT WALLET←");
  const [walletNo, setWalletNo] = useState("");
  const [isFaq, setIsFaq] = useState(false);
  const [emotion, setEmotion] = useState("neutral");
  const [isConnected, setIsConnected] = useState(false);
  const [hasEnvCamera, setEvnCamera] = useState(false);

  const webcamRef = React.useRef(null);

  const takeShot = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    document.getElementById("img").src = imageSrc;
  }, [webcamRef]);

  const connectWallet = async () => {
    try {
      if (wallet === "→CONNECT WALLET←") {
        {
          await web3Modal.clearCachedProvider();
          const provideri = await web3Modal.connect();
          await web3Modal.connect();

          if (typeof provideri.selectedAddress !== "undefined") {
            setWallet(`→${provideri.selectedAddress.slice(0, 7)}←`);
            setWalletNo(provideri.selectedAddress);
          } else {
            setWallet(`→${provideri.accounts[0].slice(0, 7)}←`);
            setWalletNo(provideri.accounts[0]);
          }
          setIsConnected(true);
          new ethers.providers.Web3Provider(provideri);
          setProvider(provideri);

          Contract.setProvider(provideri);
        }
      }
    } catch (error) {
      //alert(error);
    }
  };

  async function changeMoodMobile(feel) {
    if (isConnected) {
      changeMood(feel);
    } else {
      document.getElementById("overlay").classList.remove("d-block");
      document.getElementById("overlay").classList.add("d-none");
      await connectWallet();
      document.getElementById("overlay").classList.add("d-block");
      document.getElementById("overlay").classList.remove("d-none");
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  let stop = () => {
    console.log("whatever");
    document
      .getElementsByTagName("video")[0]
      .srcObject.getVideoTracks()[0]
      .stop();
  };

  const mint = async () => {
    let valueIs = 20000000000000000;
    if (window.location.pathname === "/free") {
      console.log("this is free");
      valueIs = 0;
    }
    try {
      if (isConnected) {
        Contract.setProvider(provider);
        var contract = new Contract(NFTContract, contractAddress);
        console.log(contract);
        contract.methods
          .mint(emotion, colors, eyeLevel, mouthSize, faceSpeed, faceBlur)
          .send({
            from: walletNo,
            gas: 1000000,
            value: valueIs,
          })
          .once("transactionHash", function (hash) {
            setSection(4);
            console.log("minting");
            setTransactionHash(hash);
          })
          .then(async function (result) {
            setSection(5);
            setMintedTokenId(result.events.Transfer.returnValues.tokenId);
            let resulti = await getTotalMinted();
            setTotalMinted(resulti);
          })
          .catch((x) => {
            console.log(x);
            //document.getElementById("mintButtonText").innerText = "Error";
          });
      } else {
        alert("not connected");
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  const changeMood = async (mood) => {
    try {
      if (isConnected) {
        Contract.setProvider(provider);
        var contract = new Contract(NFTContract, contractAddress);
        console.log(contract);
        contract.methods
          .poke(mood)
          .send({
            from: walletNo,
            gas: 80000,
          })
          .once("transactionHash", async function () {})
          .then(async function (result) {
            let colorz0 = getColorIndex();
            let colorz1 = getColorIndex();
            let colorz2 = getColorIndex();
            let colorz3 = getColorIndex();

            console.log(mood);
            let artResponse0 = await contractForCalls.methods
              .genArt(mood, colorz0.replace(colorz0[0], "8"), 0, 0, 0, 0)
              .call();
            let artResponse1 = await contractForCalls.methods
              .genArt(mood, colorz1.replace(colorz1[0], "8"), 0, 0, 2, 2)
              .call();
            let artResponse2 = await contractForCalls.methods
              .genArt(mood, colorz2.replace(colorz2[0], "8"), 0, 0, 0, 0)
              .call();

            let artResponse3 = await contractForCalls.methods
              .genArt(mood, colorz3.replace(colorz3[0], "8"), 0, 0, 1, 1)
              .call();

            console.log(artResponse0);
            let made4Faces = make4Faces(
              artResponse0,
              artResponse1,
              artResponse2,
              artResponse3
            );
            setLilFace0(
              `data:image/svg+xml;base64,${base64.encode(made4Faces)}`
            );
            document.getElementById("mobileChangeMessage").innerText =
              "The groups mood is " + mood;
            document.getElementById("overlay").classList.remove("d-block");
            document.getElementById("overlay").classList.add("d-none");
          })
          .catch((x) => {
            alert(x);
          });
      } else {
        alert("not connected");
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  const loadModels = () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri("/models"),
    ]).then(() => {
      //faceDetection();
    });
  };

  function arrayCreate(array, size) {
    var result = [];
    array.forEach(
      (function iter(parts) {
        return function (v) {
          var temp = parts.concat(v);
          if (parts.includes(v)) {
            return;
          }
          if (temp.length === size) {
            result.push(temp);
            return;
          }
          array.forEach(iter(temp));
        };
      })([])
    );
    return result;
  }

  function getColorIndex() {
    let array = arrayCreate([0, 1, 2, 3, 4, 5, 6, 7], 5).map((a) => a.join(""));
    var item = array[Math.floor(Math.random() * array.length)];
    return item;
  }

  function findIndexOfGreatest(array) {
    var greatest;
    var indexOfGreatest;
    for (var i = 0; i < array.length; i++) {
      if (!greatest || array[i] > greatest) {
        greatest = array[i];
        indexOfGreatest = i;
      }
    }
    return indexOfGreatest;
  }

  function getDistance(x1, y1, x2, y2) {
    let y = x2 - x1;
    let x = y2 - y1;

    return Math.sqrt(x * x + y * y);
  }

  function scale(number, inMin, inMax, outMin, outMax) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  function random_item(items) {
    return items[Math.floor(Math.random() * items.length)];
  }

  async function capture() {
    try {
      let emoArray = [];
      await setSection(2);
      const img = await document.getElementById("img");

      let chosenSpeen = await random_item(speedArray);
      let chosenBlur = await random_item(blurArray);
      console.log("vars");
      console.log(chosenSpeen);
      await setFaceSpeed(chosenSpeen);
      await setFaceBlur(chosenBlur);

      document.body.style.backgroundColor = "#000000";
      console.log("start");
      const detections = await faceapi
        .detectAllFaces(img, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceExpressions();

      console.log(detections);
      let imageArr = ["happy.png", "sad.png", "neutral.png"];
      let valueArr = ["happy", "sad", "neutral"];

      let eyeLeft = detections[0].landmarks._positions[39];
      let eyeRight = detections[0].landmarks._positions[42];
      let mouthLeft = detections[0].landmarks._positions[48];
      let mouthRight = detections[0].landmarks._positions[54];
      let noseCoords = detections[0].landmarks._positions[33];

      let eyeDistance = getDistance(
        eyeLeft["_x"],
        eyeLeft["_y"],
        eyeRight["_x"],
        eyeRight["_y"]
      );

      console.log(eyeDistance);

      let mouthDistance = getDistance(
        mouthLeft["_x"],
        mouthLeft["_y"],
        mouthRight["_x"],
        mouthRight["_y"]
      );

      let LENDistance = getDistance(
        eyeLeft["_x"],
        eyeLeft["_y"],
        noseCoords["_x"],
        noseCoords["_y"]
      );

      let RENDistance = getDistance(
        eyeRight["_x"],
        eyeRight["_y"],
        noseCoords["_x"],
        noseCoords["_y"]
      );

      let averageEyetoNose = (LENDistance + RENDistance) / 2;
      let magicNumber = (eyeDistance / averageEyetoNose) * 100;

      let scaled = scale(magicNumber, 30, 90, 1, 120);
      let scaledRounded = Math.round(scaled);

      setEyeLevel(scaledRounded);
      console.log("that");

      let scaledMouth = scale(
        (mouthDistance * 3) % averageEyetoNose,
        0,
        60,
        0,
        40
      );

      setMouthSize(Math.round(scaledMouth));

      emoArray.push(detections[0].expressions.happy);
      emoArray.push(detections[0].expressions.sad);
      emoArray.push(detections[0].expressions.neutral);

      console.log(emoArray);

      setEmotion(valueArr[findIndexOfGreatest(emoArray)]);

      let colorsAre = await getColorIndex();
      setColors(colorsAre);
      let artResponse = await contractForCalls.methods
        .genArt(
          valueArr[findIndexOfGreatest(emoArray)],
          colorsAre,
          scaledRounded,
          Math.round(scaledMouth),
          chosenSpeen,
          chosenBlur
        )
        .call();

      setFace("data:image/svg+xml;base64," + base64.encode(artResponse));
      setProcessing(false);
      document.body.style.overflow = "";
    } catch (error) {
      document.body.style.overflow = "";
      stop();
      setSection(3);
      setProcessing(false);
    }
  }

  useEffect(async () => {
    getWindowSize();
    let result = await getTotalMinted();
    setTotalMinted(result);
    console.log("snapshot.val()");
    // firebase.on(
    //   "value",
    //   (snapshot) => {
    //     //console.log(snapshot.val());
    //   },
    //   (errorObject) => {
    //     console.log("The read failed: " + errorObject.name);
    //   }
    // );

    //BRING IT BACK
    document.body.style.backgroundColor = "#FF00FF";
    //document.body.style.backgroundColor = "black";

    //document.body.style.overflow = "hidden";
    videoRef && loadModels();
    getWindowSize();
  }, []);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        document.getElementsByTagName("video")[0].srcObject = stream;
      })
      .catch((e) => console.log(e));
  };

  window.addEventListener("resize", function () {
    getWindowSize();
  });

  function getWindowSize() {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }

  return (
    <div>
      <div
        id="topMenu"
        style={{
          zIndex: 5,
          width: "100%",
          top: "5px",
          position: "absolute",
        }}
      >
        <div
          style={{
            color: "#FF00FF",
          }}
          id="wallen-and-icon"
          className={
            (section === 0 && isConnected) ||
            (section === 0 && isFaq) ||
            section > 0
              ? "d-flex justify-content-md-between justify-content-between mx-3 pt-md-2 pt-3"
              : "d-flex justify-content-md-between justify-content-center mx-3 pt-md-2 pt-3"
          }
        >
          <div>
            <div className={!isFaq ? "d-none" : "d-block"}>
              <h2
                className="clickable"
                style={{ color: "#000000" }}
                onClick={(x) => {
                  setIsFaq(false);
                }}
              >
                <span>→BACK←</span>
              </h2>
            </div>
            <div className={isFaq ? "d-none" : "d-block clickable"}>
              <img
                onClick={(x) => {
                  document.body.style.backgroundColor = "#FF00FF";
                  document.body.style.overflow = "";
                  setSection(0);
                }}
                src={
                  section === 1 ||
                  section === 2 ||
                  section === 3 ||
                  section === 4 ||
                  section === 5 ||
                  section === 6
                    ? "logo-pink.png"
                    : "logo-black.png"
                }
                alt=""
              />
            </div>
            <div className="d-none d-md-block">
              <h2
                className={
                  section === 0 || section === 1 ? "d-none" : "d-block"
                }
              >
                Soft Faces<p>{("0" + totalMinted).slice(-2)}/77</p>
              </h2>
            </div>
          </div>
          <div className={isFaq ? "d-none" : "d-block"}>
            <p
              id="walleth2"
              className={
                (section === 2 && !isConnected) ||
                (windowWidth < 0 && section !== 1) ||
                (!isConnected && section === 0 && windowWidth < 768)
                  ? "d-none"
                  : "d-block text-right clickable"
              }
              style={
                section === 0 ? { color: "#000000" } : { color: "#FF00FF" }
              }
              onClick={connectWallet}
            >
              {section === 1 ? (
                <span>{`Take a selfie, fren!\n(No face data saved ever)`}</span>
              ) : (
                wallet
              )}
            </p>
          </div>
          <div className={!isFaq ? "d-none" : "d-block"}>
            <img className="pt-2" src="logo-black.png" alt="" />
          </div>
        </div>
      </div>
      <div
        id="section0"
        className={section === 0 ? "d-block" : "d-none"}
        style={{ height: "100vh", overflowY: "hidden !important" }}
      >
        <svg
          className="d-md-block d-none"
          style={{ position: "absolute", zIndex: -2 }}
          viewBox="0 0 100 48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="f1" x="-100%" y="-90%" width="12000%" height="12000%">
              <feGaussianBlur stdDeviation="4.7" />
            </filter>
          </defs>

          <clipPath id="cut-off">
            <rect x="0" y="0" width="100%" height="50px" />
          </clipPath>
          <defs>
            <radialGradient id="myGradient">
              <stop offset="60%" stopColor="#FF00FF" />
              <stop offset="65%" stopColor="#FEA0C9" />
              <stop offset="69%" stopColor="#FFFF9B" />
            </radialGradient>
          </defs>
          <g>
            <rect fill="#FFFF9B" x="0" y="0" width="100%" height="50px" />
            <circle
              filter="url(#f1)"
              fill="#FEA0C9"
              cx="50"
              cy="89"
              r="70"
              clip-path="url(#cut-off)"
            />
            <circle
              filter="url(#f1)"
              fill="#FF00FF"
              cx="50"
              cy="89"
              r="70"
              clip-path="url(#cut-off)"
            />
          </g>
        </svg>
        <svg
          className="d-block d-md-none"
          style={{ position: "absolute", zIndex: -1 }}
          viewBox="0 0 100 120"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="f2" x="-100%" y="-90%" width="12000%" height="12000%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10.5" />
            </filter>
          </defs>

          <clipPath id="cut-off2">
            <rect x="0" y="50" width="100%" height="200px" />
          </clipPath>
          <defs>
            <radialGradient id="myGradient">
              <stop offset="60%" stopColor="#FF00FF" />
              <stop offset="65%" stopColor="#FEA0C9" />
              <stop offset="69%" stopColor="#FFFF9B" />
            </radialGradient>
          </defs>
          <g>
            <rect fill="#FFFF9B" x="0" y="0" width="100%" height="120px" />
            <circle
              filter="url(#f2)"
              fill="#FEA0C9"
              cx="50"
              cy="138"
              r="60"
              clip-path="url(#cut-off2)"
            />
            <circle
              filter="url(#f2)"
              fill="#FF00FF"
              cx="50"
              cy="138"
              r="60"
              clip-path="url(#cut-off2)"
            />
          </g>
        </svg>
        <div className="container" style={{ height: "100vh", zIndex: 2 }}>
          <table
            style={{
              height: "100%",
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <tr style={{ width: "100%" }} className="mx-auto">
              <td valign="top" style={{ textAlign: "center" }}>
                <div
                  id="main"
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="pt-md-4 pt-3">
                    <div className={isFaq ? "d-none" : "d-block"}>
                      <h1
                        style={{
                          textAlign: "center !important",
                          color: "black",
                        }}
                        className="text-center header mt-5 mt-md-0 pt-1"
                      >
                        soft faces
                      </h1>
                      <h2
                        style={{
                          color: "black",
                        }}
                        className="text-center"
                      >
                        {("0" + totalMinted).slice(-2)}/77 MINTED
                      </h2>
                    </div>

                    <div
                      className={isFaq === false ? "d-block pt-lg-2" : "d-none"}
                    >
                      <div className="text-center pt-5 pt-md-5 pt-lg-5 my-auto">
                        <div style={{ maxWidth: "400px", margin: "auto" }}>
                          <img
                            className="text-center px-2 px-md-0 pt-2 pt-md-0 pt-lg-2 img-fluid clickable"
                            onClick={(x) => {
                              setSection(1);
                              document.body.scrollTop =
                                document.documentElement.scrollTop = 0;
                              document.body.style.overflow = "hidden";
                              setEvnCamera(true);
                              startVideo();
                            }}
                            id="gif"
                            src="photo-cta1.png"
                            alt=""
                          />
                        </div>

                        <h2
                          className="mx-auto pt-4 introText"
                          style={{
                            color: "#FFFF00",
                            maxWidth: "375px",
                            margin: "auto",
                          }}
                        >
                          Take a selfie to create a generative Soft Face NFT
                          that is stored, rendered and interacts with each other
                          on chain.
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{ width: "100%" }} className="mx-auto">
              <td valign="bottom">
                <div id="footer">
                  <div>
                    <div>
                      <div className={isFaq === true ? "d-block" : "d-none"}>
                        <Faq></Faq>
                        <img
                          onClick={(x) => {
                            setIsFaq(false);
                          }}
                          className="text-center mx-auto d-md-block d-none"
                          src="logo-yellow.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <div
                      className="pt-5 pt-md-2"
                      style={{ color: "#FFFF00" }}
                      id="mainBottom"
                    >
                      <p
                        className={
                          !isFaq ? "text-center font22Px px-2" : "d-none"
                        }
                        style={{ color: "#000000" }}
                        onClick={async () => {
                          setSection(6);
                          document.body.style.backgroundColor = "#000000";
                          let emotion = await calcEmotion();
                          let colorz0 = getColorIndex();
                          let colorz1 = getColorIndex();
                          let colorz2 = getColorIndex();
                          let colorz3 = getColorIndex();

                          console.log(emotion);
                          let artResponse0 = await contractForCalls.methods
                            .genArt(
                              emotion,
                              colorz0.replace(colorz0[0], "8"),
                              0,
                              0,
                              0,
                              0
                            )
                            .call();
                          let artResponse1 = await contractForCalls.methods
                            .genArt(
                              emotion,
                              colorz1.replace(colorz1[0], "8"),
                              0,
                              0,
                              2,
                              2
                            )
                            .call();
                          let artResponse2 = await contractForCalls.methods
                            .genArt(
                              emotion,
                              colorz2.replace(colorz2[0], "8"),
                              0,
                              0,
                              0,
                              0
                            )
                            .call();

                          let artResponse3 = await contractForCalls.methods
                            .genArt(
                              emotion,
                              colorz3.replace(colorz3[0], "8"),
                              0,
                              0,
                              1,
                              1
                            )
                            .call();

                          console.log(artResponse0);
                          let made4Faces = make4Faces(
                            artResponse0,
                            artResponse1,
                            artResponse2,
                            artResponse3
                          );
                          setLilFace0(
                            `data:image/svg+xml;base64,${base64.encode(
                              made4Faces
                            )}`
                          );
                        }}
                      >
                        →CHANGE MOODs←
                      </p>
                      <div className="d-block d-md-flex flex-wrap  justify-content-center pt-0 pt-md-2 pt-lg-2">
                        <div
                          className={isConnected ? "d-none" : "d-block"}
                          style={{
                            zIndex: 999,
                          }}
                          onClick={(x) => {
                            connectWallet();
                          }}
                        >
                          <p className="d-block d-md-none text-center font22Px px-2">
                            →CONNECT WALLET←
                          </p>
                        </div>

                        <p
                          onClick={(x) => {
                            console.log("tes");

                            setIsFaq(true);
                          }}
                          className="text-center font22Px px-2 clickable"
                        >
                          →FAQ
                        </p>
                        <p className="text-center font22Px px-2">→CONTRACT</p>
                        <p className="text-center font22Px px-2">→OPENSEA</p>
                        <p className="text-center font22Px px-2">→TWITTER</p>
                        <img
                          className="d-block d-md-none mx-auto pb-4 py-3 pt-md-none"
                          src="logo-yellow.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <div id="section1" className={section === 1 ? "d-block" : "d-none"}>
          <div
            className="mt-5 buttonResize"
            onClick={async (x) => {
              await setFace(
                "data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0IgogIHZpZXdCb3g9IjAgMCAxMDAgMTAwIgo+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJmMiIgeD0iLTEwMCUiIHk9Ii0xMDAlIiB3aWR0aD0iMTIwMDAlIiBoZWlnaHQ9IjEyMDAwJSI+CiAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0iU291cmNlR3JhcGhpYyIgc3RkRGV2aWF0aW9uPSIxMC41IiAvPgogICAgPC9maWx0ZXI+CiAgPC9kZWZzPgogIDxnPgogICAgPHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0iI0ZGRkY5QiIgLz4KICAgIDxjaXJjbGUgZmlsdGVyPSJ1cmwoI2YyKSIgZmlsbD0iI0ZFQTBDOSIgY3g9IjUwIiBjeT0iMTEwIiByPSI2MCIgLz4KICAgIDxjaXJjbGUKICAgICAgY2xhc3M9ImlubmVyQ2lyY2xlIgogICAgICBmaWx0ZXI9InVybCgjZjIpIgogICAgICBmaWxsPSIjRkYwMEZGIgogICAgICBjeD0iNTAiCiAgICAgIGN5PSIxMTAiCiAgICAgIHI9IjYwIgogICAgPgogICAgICA8YW5pbWF0ZQogICAgICAgIGF0dHJpYnV0ZU5hbWU9InIiCiAgICAgICAgdmFsdWVzPSI5MDs1MDs5MCIKICAgICAgICBkdXI9IjIwMDBtcyIKICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgLz4KICAgIDwvY2lyY2xlPgogIDwvZz4KPC9zdmc+"
              );
              await setProcessing(true);
              await takeShot();
              await capture();
              stop();
            }}
            style={{
              zIndex: 30,
              position: "fixed",
              left: "50%",
              maxWidth: "20px !important",
              transform: "translateX(-50%)",
            }}
          >
            <svg
              height="100%"
              width="100%"
              viewBox="0 0 120 120"
              align="center"
              id="center-svg"
              xmlns="http://www.w3.org/2000/svg"
              cursor="pointer"
              pointerEvents="all"
            >
              <rect
                width="15"
                height="15"
                transform="translate(15 15)"
                fill="#FF00FF"
                strokeWidth="0"
              />
              <rect
                width="15"
                height="15"
                transform="translate(15 90)"
                fill="#FF00FF"
                strokeWidth="0"
              />
              <rect
                width="15"
                height="15"
                transform="translate(90 15)"
                fill="#FF00FF"
                strokeWidth="0"
              />
              <rect
                width="15"
                height="15"
                transform="translate(90 90)"
                fill="#FF00FF"
                strokeWidth="0"
              />
              <rect
                width="15"
                height="60"
                transform="translate(0 30)"
                fill="#FF00FF"
                strokeWidth="0"
              />
              <rect
                width="15"
                height="60"
                transform="translate(105 30)"
                fill="#FF00FF"
                strokeWidth="0"
              />
              <rect
                width="60"
                height="15"
                transform="translate(30 0)"
                fill="#FF00FF"
                strokeWidth="0"
              />
              <rect
                width="60"
                height="15"
                rx="0"
                ry="0"
                transform="translate(30 105)"
                fill="#FF00FF"
                strokeWidth="0"
              />
              <g>
                <path
                  className="node_hover"
                  d="M15,30v60h15v15h60v-15h15v-60h-15v-15h-60v15h-15Z"
                />
              </g>
            </svg>
          </div>
          <div
            style={{
              zIndex: 4,
              position: "fixed",
              left: "20px",
              width: "100%",
              margin: "0 auto",
            }}
            className="d-inline backAdjust"
          >
            <p
              id="backFromPhoto"
              className="hoverable d-inline fontAdjust"
              onClick={(x) => {
                window.location.reload();
              }}
            >
              →BACK←
            </p>
          </div>
          <div
            className="text-center"
            style={{ backgroundColor: "black", height: "100vh" }}
          >
            <div
              style={{
                zIndex: 1,
              }}
            >
              {hasEnvCamera ? (
                <div style={{ overflowX: "hidden !important" }}>
                  <div
                    className="outer"
                    style={{ overflowX: "hidden !important" }}
                  >
                    <div
                      className="middle"
                      style={{ overflowX: "hidden !important" }}
                    >
                      <div
                        className="inner"
                        style={{ overflowX: "hidden !important" }}
                      >
                        <Webcam
                          className="my-auto"
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <img id="img" className="d-none" alt="" />
                  <canvas
                    width="800"
                    height="800"
                    className="d-none"
                    id="file-preview"
                  ></canvas>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <div
          id="section2"
          style={{ backgroundColor: "#000000" }}
          className={section === 2 ? "d-block" : "d-none"}
        >
          <div className="" style={{ height: "100vh", zIndex: 2 }}>
            <table
              style={{
                height: "100%",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr style={{ width: "100%" }} className="mx-auto">
                <td valign="top" style={{ textAlign: "center" }}>
                  <div
                    id="main"
                    style={{
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="row sectionTopPadding"
                      style={{
                        margin: 0,
                        top: "50%",
                      }}
                    >
                      <div className="col-3 my-auto d-none d-md-block">
                        <p className="text-left mt-md-5">
                          <span
                            className="hoverable"
                            onClick={(x) => {
                              setEvnCamera(true);
                              startVideo();
                              setSection(1);
                              document.body.scrollTop =
                                document.documentElement.scrollTop = 0;
                              document.body.style.overflow = "hidden";
                              setFace(
                                "data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0IgogIHZpZXdCb3g9IjAgMCAxMDAgMTAwIgo+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJmMiIgeD0iLTEwMCUiIHk9Ii0xMDAlIiB3aWR0aD0iMTIwMDAlIiBoZWlnaHQ9IjEyMDAwJSI+CiAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0iU291cmNlR3JhcGhpYyIgc3RkRGV2aWF0aW9uPSIxMC41IiAvPgogICAgPC9maWx0ZXI+CiAgPC9kZWZzPgogIDxnPgogICAgPHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0iI0ZGRkY5QiIgLz4KICAgIDxjaXJjbGUgZmlsdGVyPSJ1cmwoI2YyKSIgZmlsbD0iI0ZFQTBDOSIgY3g9IjUwIiBjeT0iMTEwIiByPSI2MCIgLz4KICAgIDxjaXJjbGUKICAgICAgY2xhc3M9ImlubmVyQ2lyY2xlIgogICAgICBmaWx0ZXI9InVybCgjZjIpIgogICAgICBmaWxsPSIjRkYwMEZGIgogICAgICBjeD0iNTAiCiAgICAgIGN5PSIxMTAiCiAgICAgIHI9IjYwIgogICAgPgogICAgICA8YW5pbWF0ZQogICAgICAgIGF0dHJpYnV0ZU5hbWU9InIiCiAgICAgICAgdmFsdWVzPSI5MDs1MDs5MCIKICAgICAgICBkdXI9IjIwMDBtcyIKICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgLz4KICAgIDwvY2lyY2xlPgogIDwvZz4KPC9zdmc+"
                              );
                            }}
                          >
                            →TRY AGAIN←
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 text-center nopadding">
                        <p
                          style={{ color: "#FF00FF" }}
                          className="text-left text-md-center pl-3 pl-md-none"
                        >
                          {!isProcessing ? (
                            !isConnected ? (
                              <p
                                style={{ color: "#FF00FF" }}
                                className="text-left text-md-center infoText"
                              >
                                Your Soft Face:
                              </p>
                            ) : (
                              <p
                                style={{ color: "#FF00FF" }}
                                className="text-left text-md-center infoText"
                              >
                                Wallet connected. Mint?
                              </p>
                            )
                          ) : (
                            <p
                              style={{ color: "#FF00FF" }}
                              className="text-left text-md-center minting infoText"
                            >
                              Processing
                            </p>
                          )}
                        </p>
                        <div
                          className=""
                          style={{ maxWidth: "475px", margin: "auto" }}
                        >
                          <div
                            style={{ maxWidth: "100%" }}
                            className="img-fluid mx-auto d-block"
                          >
                            <img
                              className="center-block img-fluid"
                              src={face}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 d-block d-md-none centerThis">
                        <div className="d-flex justify-content-between mt-4 mt-md-3">
                          <p
                            className="hoverable fontAdjust mt-md-5"
                            onClick={(x) => {
                              setFace(
                                "data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0IgogIHZpZXdCb3g9IjAgMCAxMDAgMTAwIgo+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJmMiIgeD0iLTEwMCUiIHk9Ii0xMDAlIiB3aWR0aD0iMTIwMDAlIiBoZWlnaHQ9IjEyMDAwJSI+CiAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0iU291cmNlR3JhcGhpYyIgc3RkRGV2aWF0aW9uPSIxMC41IiAvPgogICAgPC9maWx0ZXI+CiAgPC9kZWZzPgogIDxnPgogICAgPHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0iI0ZGRkY5QiIgLz4KICAgIDxjaXJjbGUgZmlsdGVyPSJ1cmwoI2YyKSIgZmlsbD0iI0ZFQTBDOSIgY3g9IjUwIiBjeT0iMTEwIiByPSI2MCIgLz4KICAgIDxjaXJjbGUKICAgICAgY2xhc3M9ImlubmVyQ2lyY2xlIgogICAgICBmaWx0ZXI9InVybCgjZjIpIgogICAgICBmaWxsPSIjRkYwMEZGIgogICAgICBjeD0iNTAiCiAgICAgIGN5PSIxMTAiCiAgICAgIHI9IjYwIgogICAgPgogICAgICA8YW5pbWF0ZQogICAgICAgIGF0dHJpYnV0ZU5hbWU9InIiCiAgICAgICAgdmFsdWVzPSI5MDs1MDs5MCIKICAgICAgICBkdXI9IjIwMDBtcyIKICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgLz4KICAgIDwvY2lyY2xlPgogIDwvZz4KPC9zdmc+"
                              );
                              setSection(1);
                              document.body.scrollTop =
                                document.documentElement.scrollTop = 0;
                            }}
                          >
                            →BACK←
                          </p>
                          {!isConnected ? (
                            <p
                              className="text-right mt-md-5 fontAdjust"
                              onClick={connectWallet}
                            >
                              <span
                                className={
                                  isProcessing ? "d-none" : "d-block hoverable"
                                }
                              >
                                →CONNECT WALLET←
                              </span>
                            </p>
                          ) : (
                            <p
                              className="text-right d-inline fontAdjust mt-md-5"
                              onClick={mint}
                            >
                              <span
                                className={
                                  isProcessing ? "d-none" : "d-block hoverable"
                                }
                              >
                                →MINT←
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-3 my-auto d-none d-md-block">
                        {!isConnected ? (
                          <p
                            className="text-right mt-md-5"
                            onClick={connectWallet}
                          >
                            <span className="hoverable">→CONNECT WALLET←</span>
                          </p>
                        ) : (
                          <p className="justify-content-end d-flex mt-md-5">
                            <span className="hoverable" onClick={mint}>
                              →MINT←
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className={isProcessing ? "d-none" : "d-block"}>
                      <p
                        className="font22Px text-center pt-md-2"
                        style={{ color: "#FFFF00" }}
                      >
                        <span
                          className="d-block d-md-none"
                          style={{ color: "#FF00FF" }}
                        >
                          PROPERTIES:
                        </span>
                        {`>Mood: ${capitalizeFirstLetter(
                          emotion
                        )}<\n>Blur: Level ${faceBlur + 1}<\n>Animation: ${
                          speedArrayName[faceSpeed]
                        }<\n`}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                style={{ width: "100%" }}
                className={!isProcessing ? "mx-auto" : "d-none"}
              >
                <td valign="bottom">
                  <Footer></Footer>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          id="section3"
          style={{ backgroundColor: "#000000" }}
          className={section === 3 ? "d-block" : "d-none"}
        >
          <div className="" style={{ height: "100vh", zIndex: 2 }}>
            <table
              style={{
                height: "100%",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr style={{ width: "100%" }} className="mx-auto">
                <td valign="top" style={{ textAlign: "center" }}>
                  <div
                    id="main"
                    style={{
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="row sectionTopPadding"
                      style={{
                        margin: 0,
                        top: "50%",
                      }}
                    >
                      <div className="col-3 my-auto d-none d-md-block">
                        <p className="text-left mt-md-5">
                          <span
                            onClick={(x) => {
                              window.location.reload();
                            }}
                            className="hoverable"
                          >
                            →HOME←
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 nopadding">
                        <p
                          style={{ color: "#FF00FF" }}
                          className="text-left text-md-center infoText pl-3 pl-md-none"
                        >
                          {"No face detected.\nLook into the camera ◑◑"}
                        </p>
                        <div
                          style={{ maxWidth: "475px", margin: "auto" }}
                          className=""
                        >
                          <div
                            style={{ maxWidth: "100%" }}
                            className=" mx-auto d-block "
                          >
                            <img
                              className="center-block img-fluid "
                              src="data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0IgogIHZpZXdCb3g9IjAgMCAxMDAgMTAwIgo+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJmMiIgeD0iLTEwMCUiIHk9Ii0xMDAlIiB3aWR0aD0iMTIwMDAlIiBoZWlnaHQ9IjEyMDAwJSI+CiAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0iU291cmNlR3JhcGhpYyIgc3RkRGV2aWF0aW9uPSIxMC41IiAvPgogICAgPC9maWx0ZXI+CiAgPC9kZWZzPgogIDxnPgogICAgPHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0iI0ZGMDBGRiIgLz4KICAgIDxjaXJjbGUgZmlsdGVyPSJ1cmwoI2YyKSIgZmlsbD0iI0ZFQTBDOSIgY3g9IjUwIiBjeT0iMTEwIiByPSI2MCIgLz4KICAgIDxjaXJjbGUKICAgICAgY2xhc3M9ImlubmVyQ2lyY2xlIgogICAgICBmaWx0ZXI9InVybCgjZjIpIgogICAgICBmaWxsPSIjRkYwMDAwIgogICAgICBjeD0iNTAiCiAgICAgIGN5PSIxMTAiCiAgICAgIHI9IjYwIgogICAgPgogICAgICA8YW5pbWF0ZQogICAgICAgIGF0dHJpYnV0ZU5hbWU9InIiCiAgICAgICAgdmFsdWVzPSI5MDs1MDs5MCIKICAgICAgICBkdXI9IjIwMDBtcyIKICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgLz4KICAgIDwvY2lyY2xlPgogIDwvZz4KPC9zdmc+"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 my-auto">
                        <p className="text-left text-md-right fontAdjust mt-3 mt-md-0">
                          <span
                            className="d-inline hoverable mt-md-5"
                            onClick={(x) => {
                              document.body.scrollTop =
                                document.documentElement.scrollTop = 0;
                              document.body.style.overflow =
                                "hidden !important";
                              setEvnCamera(true);
                              startVideo();
                              setSection(1);
                            }}
                          >
                            →TRY AGAIN←
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style={{ width: "100%" }} className="mx-auto">
                <td valign="bottom">
                  <Footer></Footer>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          id="section4"
          style={{ backgroundColor: "#000000" }}
          className={section === 4 ? "d-block" : "d-none"}
        >
          <div className="" style={{ height: "100vh", zIndex: 2 }}>
            <table
              style={{
                height: "100%",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr style={{ width: "100%" }} className="mx-auto">
                <td valign="top" style={{ textAlign: "center" }}>
                  <div
                    id="main"
                    style={{
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="row sectionTopPadding"
                      style={{
                        margin: 0,
                        top: "50%",
                      }}
                    >
                      <div className="col-3 my-auto"></div>
                      <div className="col-12 col-md-6 text-center nopadding">
                        <p
                          style={{ color: "#FF00FF" }}
                          className="text-md-center text-left minting infoText pl-3 pl-md-none"
                        >
                          Minting
                        </p>
                        <div style={{ maxWidth: "475px", margin: "auto" }}>
                          <div
                            style={{ maxWidth: "100%" }}
                            className=" mx-auto d-block"
                          >
                            <img
                              className="center-block img-fluid"
                              src={face}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-3 my-auto"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style={{ width: "100%" }} className="mx-auto">
                <td valign="bottom">
                  <div className="d-none d-md-block">
                    <Footer></Footer>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          id="section5"
          style={{ backgroundColor: "#000000" }}
          className={section === 5 ? "d-block" : "d-none"}
        >
          <div className="" style={{ height: "100vh", zIndex: 2 }}>
            <table
              style={{
                height: "100%",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr style={{ width: "100%" }} className="mx-auto">
                <td valign="top" style={{ textAlign: "center" }}>
                  <div
                    id="main"
                    style={{
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="row sectionTopPadding"
                      style={{
                        margin: 0,
                        top: "50%",
                      }}
                    >
                      <div className="col-3 my-auto d-none d-md-block">
                        <p className="text-left mt-md-5">
                          <span
                            className="hoverable"
                            onClick={(x) => {
                              window.location.reload();
                            }}
                          >
                            →HOME←
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 text-center nopadding">
                        <p
                          style={{ color: "#FF00FF" }}
                          className="text-md-center text-left infoText pl-3 pl-md-none"
                        >
                          You minted soft Face #{mintedTokenId}
                        </p>
                        <div style={{ maxWidth: "475px", margin: "auto" }}>
                          <div
                            style={{ maxWidth: "100%" }}
                            className=" mx-auto d-block"
                          >
                            <img
                              className="center-block img-fluid"
                              src={face}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-3 my-auto d-none d-md-block">
                        <div className="text-right mt-md-5">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://rinkeby.etherscan.io/tx/${transactionHash}`}
                          >
                            <p className="hoverable d-inline-block">
                              →ETHERSCAN←
                            </p>
                          </a>
                        </div>

                        <div className="text-right">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://testnets.opensea.io/assets/${contractAddress}/${mintedTokenId}`}
                          >
                            <p className="hoverable d-inline-block">
                              →OPENSEA←
                            </p>
                          </a>
                        </div>
                      </div>
                      <div className="col-12 d-block d-md-none">
                        <div className="d-flex justify-content-between mt-4 mt-md-3">
                          <p className="d-inline">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://rinkeby.etherscan.io/tx/${transactionHash}`}
                            >
                              <p className=" hoverable d-inline fontAdjust">
                                →ETHERSCAN←
                              </p>
                            </a>
                          </p>
                          <p className="d-inline">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://testnets.opensea.io/assets/${contractAddress}/${mintedTokenId}`}
                            >
                              <p className=" hoverable d-inline fontAdjust">
                                →OPENSEA←
                              </p>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style={{ width: "100%" }} className="mx-auto">
                <td valign="bottom">
                  <Footer></Footer>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          id="section6"
          style={{ backgroundColor: "#000000" }}
          className={section === 6 ? "d-block" : "d-none"}
        >
          <div className="d-block d-md-none">
            <div id="overlay" className="d-none">
              <div
                className="d-block flex-wrap justify-content-center mx-auto"
                style={{
                  zIndex: 25,
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: "150px",
                  bottom: 0,
                  margin: "auto",
                  width: "100%",
                }}
              >
                <p
                  style={{ color: "#FFFF00" }}
                  className="text-center font22Px px-2"
                >
                  Change the mood to:
                </p>
                <p
                  className="text-center px-2 pt-md-5 font22Px"
                  onClick={async () => {
                    changeMoodMobile("happy");
                  }}
                >
                  <span className="hoverable clickable ">→HAPPY←</span>
                </p>
                <p
                  className="text-center px-2 font22Px"
                  onClick={async () => {
                    changeMoodMobile("surprised");
                  }}
                >
                  <span className="hoverable clickable ">→SURPRISED←</span>
                </p>
                <p
                  className="text-center px-2 font22Px"
                  onClick={async () => {
                    changeMoodMobile("angry");
                  }}
                >
                  <span className="hoverable clickable ">→ANGRY←</span>
                </p>
                <p
                  className="text-center px-2 font22Px"
                  onClick={async () => {
                    changeMoodMobile("confused");
                  }}
                >
                  <span className="hoverable clickable">→CONFUSED←</span>
                </p>
                <p
                  className="text-center px-2 font22Px"
                  onClick={async () => {
                    changeMoodMobile("sad");
                  }}
                >
                  <span className="hoverable clickable">→SAD←</span>
                </p>
              </div>
              <div className="overlay d-block"></div>
            </div>
          </div>
          <div
            className="row sectionTopPadding"
            style={{
              margin: 0,
              top: "50%",
            }}
          >
            <div className="col-3 my-auto d-none d-md-block">
              <p
                className="hoverable"
                onClick={(x) => {
                  window.location.reload();
                }}
              >
                →BACK←
              </p>
            </div>
            <div className="col-12 col-md-6 text-center nopadding">
              <p
                style={{ color: "#FFFF00", maxWidth: "600px" }}
                className="text-left text-md-center infoText pl-3 pl-md-none mx-auto"
              >
                <span className="d-block d-md-none" id="mobileChangeMessage">
                  {`Change the groups mood\n(only Soft Face holders)`}
                </span>
                <span className="d-none d-md-block">
                  Change the groups mood for 24 hours. (only for Soft Face
                  holders)
                </span>
              </p>
              <div className="d-flex flex-row  justify-content-center">
                <div className="" style={{ maxWidth: "475px", margin: "auto" }}>
                  <div
                    style={{ maxWidth: "100%" }}
                    className="img-fluid mx-auto d-block"
                  >
                    <img
                      className="center-block img-fluid"
                      src={lilFace0}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 d-block d-md-none centerThis">
              <div className="d-flex justify-content-between mt-4 mt-md-3">
                <p
                  style={{ zIndex: 500 }}
                  className="hoverable fontAdjust"
                  onClick={(x) => {
                    window.location.reload();
                  }}
                >
                  →BACK←
                </p>
                <p
                  className="text-right d-inline hoverable fontAdjust"
                  // onClick={connectWallet}
                  onClick={() => {
                    document
                      .getElementById("overlay")
                      .classList.remove("d-none");
                    document.getElementById("overlay").classList.add("d-block");
                  }}
                >
                  <span className={isProcessing ? "d-none" : "d-block"}>
                    →CHANGE MOOD←
                  </span>
                </p>
              </div>
            </div>
            <div className="col-3 text-right my-auto d-none d-md-block">
              <p
                className="justify-content-end d-flex hoverable"
                onClick={() => {
                  if (isConnected) {
                    changeMood("happy");
                  } else {
                    document.getElementById("walleth2").classList.add("blink");
                    setTimeout(function () {
                      document
                        .getElementById("walleth2")
                        .classList.remove("blink");
                    }, 3000);
                  }
                }}
              >
                →HAPPY←
              </p>
              <p
                className="justify-content-end d-flex hoverable"
                onClick={() => {
                  if (isConnected) {
                    changeMood("surprised");
                  } else {
                    document.getElementById("walleth2").classList.add("blink");
                    setTimeout(function () {
                      document
                        .getElementById("walleth2")
                        .classList.remove("blink");
                    }, 3000);
                  }
                }}
              >
                →SURPRISED←
              </p>
              <p
                className="justify-content-end d-flex hoverable"
                onClick={() => {
                  if (isConnected) {
                    changeMood("angry");
                  } else {
                    document.getElementById("walleth2").classList.add("blink");
                    setTimeout(function () {
                      document
                        .getElementById("walleth2")
                        .classList.remove("blink");
                    }, 3000);
                  }
                }}
              >
                →ANGRY←
              </p>
              <p
                className="justify-content-end d-flex hoverable"
                onClick={() => {
                  if (isConnected) {
                    changeMood("confused");
                  } else {
                    document.getElementById("walleth2").classList.add("blink");
                    setTimeout(function () {
                      document
                        .getElementById("walleth2")
                        .classList.remove("blink");
                    }, 3000);
                  }
                }}
              >
                →CONFUSED←
              </p>
              <p
                className="justify-content-end d-flex hoverable"
                onClick={() => {
                  if (isConnected) {
                    changeMood("sad");
                  } else {
                    document.getElementById("walleth2").classList.add("blink");
                    setTimeout(function () {
                      document
                        .getElementById("walleth2")
                        .classList.remove("blink");
                    }, 3000);
                  }
                }}
              >
                →SAD←
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          section === 0 || section === 1
            ? "d-none"
            : "d-none container pt-5 pb-5"
        }
      >
        <footer style={{ color: "#FF00FF" }} className="main-footer">
          <Footer></Footer>
        </footer>
      </div>
    </div>
  );
}
