import React from "react";

function cleanSVG(svg) {
  let stingy = svg;
  let start = stingy.indexOf('<rect width="100%" height="100%"');

  let cleaner = stingy.slice(start + 50, stingy.length - 6);

  start = cleaner.indexOf("<animateMotion");
  let end = cleaner.indexOf(">", start) + 1;

  let animate = cleaner.slice(start, end);

  cleaner = cleaner.replace(animate, "");

  start = cleaner.indexOf("<animateMotion");
  end = cleaner.indexOf(">", start) + 1;

  animate = cleaner.slice(start, end);
  cleaner = cleaner.replace(animate, "");
  return cleaner;
}

export function make4Faces(face0, face1, face2, face3) {
  return `<svg xmlns="http://www.w3.org/2000/svg" height="1750" width="1750">
  <defs>
    <filter id="f1" x="-40%" y="-60%" width="800%" height="800%">
      <feGaussianBlur in="SourceGraphic" stdDeviation="30" />
    </filter>
    <filter id="f2" x="-100%" y="-150%" width="800%" height="800%">
      <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
    </filter>
    <filter id="f3" x="-100%" y="-90%" width="12000%" height="12000%">
      <feGaussianBlur in="SourceGraphic" stdDeviation="20" />
    </filter>
  </defs>
  <rect width="100%" height="100%" fill="black" stroke="#FF00FF" stroke-width="15" />
  <g>
    <animateMotion
      dur="8s"
      repeatCount="indefinite"
      path="M 40 40 l 400 200 z l 300 -30 z l 200 180 z"
    />
    ${cleanSVG(face0)}
  </g>
  <g>
    <animateMotion
      dur="5s"
      repeatCount="indefinite"
      path="M 1000 600 l 200 -500 z"
    />
    ${cleanSVG(face1)}
  </g>
  <g>
    <animateMotion
      dur="10s"
      repeatCount="indefinite"
      path="M 200 750 l 950 400 l -800 150 z"
    />
    ${cleanSVG(face2)}
  </g>
  <g>
    <animateMotion
      dur="20s"
      repeatCount="indefinite"
      path="M 100 1200 l -150 50 l 50 -500 l 700 0 l 300 600 l 0 -100 l 0 -100 z"
    />
    <g>
      <circle cx="250" cy="250" r="230" fill="#FFFF00" filter="url(#f1)" />
    </g>
    ${cleanSVG(face3)}
  </g>
</svg>`;
}
